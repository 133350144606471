<template>
  <div class="basko-box-thank-you">
    <CategoryTitle :category="categoryThankYou" />

    <v-card flat class="d-flex flex-column aling-center justify-center">
      <div class="d-flex justify-center">
        <v-img
          src="/img_layout/eugenio_survey/logo-eugenio-fine.svg"
          height="100"
          width="100"
          contain
          alt="eugenio"
        ></v-img>
      </div>
      <v-card-title class="text-h2 justify-center">Grazie</v-card-title>
      <v-card-text class="text-center"
        >Ora attendi la tua box.
        <br />
        Sta arrivando!</v-card-text
      >
      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          min-width="250"
          depressed
          large
          :to="{ name: 'EugenioHome' }"
          >Vai a eugenio</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<style lang="scss">
.basko-box-confirm-consent-container {
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";

export default {
  name: "BaskoBoxThankYou",
  components: {
    CategoryTitle
  },
  data() {
    return {
      categoryThankYou: {
        categoryId: 363542
      }
    };
  }
};
</script>
